import React, { useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "./style.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import { toastify, userlogout, clearuser, makeid } from "../redux/controller";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddIcon from "@mui/icons-material/Add";
import Areaimg from "../assets/area_icon 1.png";
import Deleteicon from "../assets/trash.png";
import Editicon from "../assets/pencil.png";
import Vectoricon from "../assets/Vector.png";
import * as turf from "@turf/turf";
import Xicon from "../assets/Delete Icon.svg"
import Draggable, {DraggableCore} from 'react-draggable';


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX
  

export default function Slotproperty() {
  var map
  var draw
  const [updatedslot, setupdatedslot] = useState(false);
  const [updatedslotdata, setupdatedslotdata] = useState(false);
  const [propertyselectedarea, setpropertyselectedarea] = useState({});
  const [updateareadata, setupdateareadata] = useState({});
  const [propertyselectedareaindex, setpropertyselectedareaindex] = useState(0);
  const [propertyselectedareas, setpropertyselectedareas] = useState([]);
  const [propertyselectedareascopy, setpropertyselectedareascopy] = useState([]);
  const [propertyselecteds, setpropertyselecteds] = useState(propertyselectedareas);
  const dispatch = useDispatch()
  const search = useLocation().search;
  const propertyid = new URLSearchParams(search).get("index");
  const selectedare = new URLSearchParams(search).get("area");
  const usertoken = useSelector((state) => state.userdata.usertoken);
  const [property, setproperty] = useState({});
  const [colorStore, setColorStore] = useState("#B87333");
  const [call, setcall] = useState(false);
  const [mapObject, setMap] = useState();
  const [mapObjectDraw, setMapDraw] = useState();
  const [deletearea, setdeletearea] = useState('');


  const [mapstyles, setmapstyles] = useState([
    {
      name: "Map",
      value: "mapbox://styles/mapbox/navigation-day-v1"
    },
    {
      name: "Satellite",
      value: "mapbox://styles/mapbox/satellite-streets-v12"
    }
  ]);

  const [selectedmapstyles, setselectedmapstyles] = useState("mapbox://styles/mapbox/satellite-streets-v12");

  function selectmapstyle(e) {
    setselectedmapstyles(e.target.value)
    mapObject.setStyle(e.target.value)
  }

  const Navigate = useNavigate();
  const Navigategoback = useNavigate();
  var selectarea = {}

  function convertdate(date) {
    return date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4)
  }


  const gotoeditarea = (i) => {
    Navigategoback(`/addproperty?index=${propertyid}&step=2&area=`+i);
  };

  const handleSave = () => {
    handleSavepopup()
    Navigate("/propertyreview?index=" + propertyid);
  };

  const handleGoback = () => {
    Navigategoback(`/addproperty?index=${propertyid}&step=2`);
  };
  const [propertypopup, setPropertypopup] = useState(false);

  const handleCanclepopup = () => {
    setPropertypopup(!propertypopup);
  };

  const handlePropertyclick = () => {
    setPropertypopup(true);
  };

  const deletePolygon = async (e) => {
    var polygonindex = Number(e.target.id.split("_")[1])
    var polygon = propertyselectedareascopy[polygonindex].name[0]
    console.log(propertyid)
    console.log(propertyselectedarea._id)
    console.log(propertyselectedareas[polygonindex].name)
    console.log(mapObject)
    var response = await dispatch(fetchData({
      "apiurl": "/candelete/slot",
      _id: propertyid,
      areaid: propertyselectedarea._id,
      slotid: propertyselectedareas[polygonindex].name,
      headers: { "x-access-token": usertoken }
    }))
    console.log(response.payload)
    
    if(response.payload && response.payload.data && response.payload.data.length){
      toastify({"message": response.payload.message})
    }
    else{
      for(var x=0; x<mapObject.getStyle().layers.length; x++){
        if(mapObject.getStyle().layers[x].id == 'outline'+ propertyselectedareaindex + polygonindex) {
          mapObject.removeLayer('outline' + propertyselectedareaindex + polygonindex);
        }
        if(mapObject.getStyle().layers[x].id == 'maine' + propertyselectedareaindex + polygonindex){
          mapObject.removeSource('maine' + propertyselectedareaindex + polygonindex);
        }
      }
      // Remove polygon to the map
      const allFeatures = mapObjectDraw.getAll();
      mapObjectDraw.delete(allFeatures.features.map((f, index) => index == polygonindex ? f.id : null))
      setpropertyselectedareas(propertyselectedareas.filter((item, index) => index !== polygonindex));
      setpropertyselectedareascopy(propertyselectedareas.filter((item, index) => index !== polygonindex));
    }
  };


  function handlepopupclose() {
    setdeletearea(false);
  }
  async function handlepopupclosedelete() {
    var response = await dispatch(fetchData({
      "apiurl": "/property/delete/area",
      _id: property._id,
      areaid: deletearea,
      headers: { "x-access-token": usertoken }
    }))
    if (response && response.payload && response.payload.status == 200) {
      toastify({ message: response.payload.message })
      setdeletearea(false);
      window.location.reload()
    } else {
      if (response.payload && response.payload.response && response.payload.response.data && response.payload.response.data.message) {
        toastify({ message: response.payload.response.data.message })
      }
      else if(response.payload && response.payload.status == 400 && response.payload.message) {
        setdeletearea(false);
        toastify({ message: response.payload.message })
      }
      if (response.payload && response.payload.response && response.payload.response.data && response.payload.response.data.message == "Invalid token provided!") {
        clearuser()
        userlogout()
        Navigate('/')
        window.location.reload()
      }
    }
  }

  const duplicatePolygon = async (e) => {
    var randomstring = await makeid(10)
    var polygonindex = Number(e.target.id.split("_")[1])
    console.log(typeof propertyselectedareascopy[polygonindex].name)
    if(typeof propertyselectedareascopy[polygonindex].name == 'object'){
      var polygon = propertyselectedareascopy[polygonindex].name[0]
      console.log(polygon)
      console.log(polygon.geometry)
      const newPolygon = {
        ...polygon,
        id: `${polygon.id}-${randomstring}`,
        geometry: {
          ...polygon.geometry,
          coordinates: polygon.geometry.coordinates
        }
      };
  
      // Add the duplicated polygon to the map
      drawRef.current.add(newPolygon);
      
      var createname = 0
      var slotcount = document.getElementById('slotscount').getElementsByClassName('propery-detailsdiv')
      if(slotcount.length){
        createname = parseInt(slotcount[slotcount.length-1].getElementsByClassName('p1')[0].innerHTML.split('-')[1])
      }
      setpropertyselectedareas(prevState => ([...prevState, {
        id: `${polygon.id}-${randomstring}`,
        "name": `A${propertyselectedareaindex+1}-${createname + 1}`,
        "size": propertyselectedareas[polygonindex].size,
        "unit": "Acres",
        "type": newPolygon.geometry.type,
        "coords": {
          "paths": newPolygon.geometry.coordinates
        }
      }]
      ))
      
      setpropertyselectedareascopy(prevState => ([...prevState, {
        "name": [newPolygon]
      }]
      ))
    }
    else if(typeof propertyselectedareascopy[polygonindex].name == 'string'){
      var polygon = propertyselectedareascopy[polygonindex]
      var geometry = {
        coordinates: polygon.coords.paths,
        type: polygon.type
      }
      var poly = {
        type: 'Feature',
        geometry: geometry,
        properties: {},
        id: `${polygon.id}-${randomstring}`
      }
      const newPolygon = {
        ...poly,
        id: `${polygon.id}-${randomstring}`,
        geometry: {
          ...geometry,
          coordinates: polygon.coords.paths
        }
      };
  
      // Add the duplicated polygon to the map
      drawRef.current.add(newPolygon);
      
      var createname = 0
      var slotcount = document.getElementById('slotscount').getElementsByClassName('propery-detailsdiv')
      if(slotcount.length){
        createname = parseInt(slotcount[slotcount.length-1].getElementsByClassName('p1')[0].innerHTML.split('-')[1])
      }
      setpropertyselectedareas(prevState => ([...prevState, {
        "id": `${polygon.id}-${randomstring}`,
        "name": `A${propertyselectedareaindex+1}-${createname + 1}`,
        "size": propertyselectedareas[polygonindex].size,
        "unit": "Acres",
        "type": polygon.type,
        "coords": {
          "paths": polygon.coords.paths
        }
      }]
      ))
      setpropertyselectedareascopy(prevState => ([...prevState, {
        "name": [newPolygon]
      }]
      ))
    }
  };

  async function handleSavepopup() {
    var body = {
      "apiurl": '/property/update/slot',
      "_id": property._id,
      areaid: propertyselectedarea._id,
      slots: propertyselectedareas,
      areaindex: propertyselectedareaindex,
      headers: { "x-access-token": usertoken }
    }
    console.log(body)
    var propertys = await dispatch(fetchData(body))
    if (propertys && propertys.payload && propertys.payload.status == 200) {
      toastify({ message: propertys.payload.message })
      window.location.reload()
    }
  };


  const mapContainerRef = useRef(null);
  const drawRef = useRef(null);
  const initialized = useRef(false)
  function calculateyards(current, previous){
    var options = {
      units: 'miles'
    };
    var distance = turf.distance(current, previous, options);
    // 1 mile = 1760 yards
    return (distance*1760).toFixed(2)
  }

  function recreatelines() {
    try{
      // console.log(map.getStyle().layers)
      // for(var x=0; x<map.getStyle().layers.length; x++){
      //   if(map.getStyle().layers[x].id && map.getStyle().layers[x].id.includes('_symbols')){
      //     var id = map.getStyle().layers[x].id
      //     map.removeLayer(id);
      //   }
        // setTimeout(()=>{
        //   if(map.getStyle().layers[x] && map.getStyle().layers[x].id && map.getStyle().layers[x].id.includes('_route')){
        //     var id = map.getStyle().layers[x].id
        //       map.removeLayer(id);
        //   }
        // }, 600)
      if(map){
        for(var x=0; x<map.getStyle().layers.length; x++){
          if(map.getStyle().layers[x].id && map.getStyle().layers[x].id.includes('_symbols')){
            var id = map.getStyle().layers[x].id
            map.removeLayer(id);
          }
        }
      }
      else{
        for(var x=0; x<mapObject.getStyle().layers.length; x++){
          if(mapObject.getStyle().layers[x].id && mapObject.getStyle().layers[x].id.includes('_symbols')){
            var id = mapObject.getStyle().layers[x].id
            mapObject.removeLayer(id);
          }
        }
      }
    }catch(err){ console.log("error")}
  }

  function getprop() {

    var body = {
      "apiurl": "/property/single",
      "propertyid": propertyid,
      headers: { "x-access-token": usertoken }
    }
    var slotsmapcopy = []
    dispatch(fetchData(body)).then((propertys) => {
      if (propertys && propertys.payload && propertys.payload.status == 200) {
        setproperty(propertys.payload.data)
        if (propertys.payload.data.area && propertys.payload.data.area.length) {
          if(selectedare){
            setpropertyselectedarea(propertys.payload.data.area[parseInt(selectedare)])
            setOpenIndex(parseInt(selectedare))
          }
          else{
            setpropertyselectedarea(propertys.payload.data.area[0])
            setOpenIndex(0)
          }
          selectarea = propertys.payload.data.area[0]
          setPropertypopup(true);
        }
        map = new mapboxgl.Map({
          container: mapContainerRef.current,
          // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
          style: selectedmapstyles, // style URL
          center: [propertys.payload.data.latlng.lng, propertys.payload.data.latlng.lat],
          zoom: propertys.payload.data.zoomlevel ? parseFloat(propertys.payload.data.zoomlevel) : 8
        });
        map.on('load', () => {
          // Add a data source containing GeoJSON data.
          map.addSource('maine', {
            'type': 'geojson',
            'data': {
              'type': 'Feature',
              'geometry': {
                'type': 'Polygon',
                // These coordinates outline Maine.
                'coordinates':
                  propertys.payload.data.geodraw.coords.paths
              }
            }
          });
          map.addLayer({
            'id': 'outline',
            'type': 'line',
            'source': 'maine',
            'layout': {},
            'paint': {
              'line-color': propertys.payload.data.propertycolor,
              'line-width': 3
            }
          });
          var slotsmap = []
          for (var y = 0; y < propertys.payload.data.area.length; y++) {
          if (propertys.payload.data.area[y].slots && propertys.payload.data.area[y].slots.length) {
            for (var x = 0; x < propertys.payload.data.area[y].slots.length; x++) {
              var slot = JSON.parse(JSON.stringify(propertys.payload.data.area[y].slots[x]))
              if (propertys.payload.data.area[y].slots[x] && propertys.payload.data.area[y].slots[x].name) {
                map.addSource('maine' + y + x, {
                  'type': 'geojson',
                  'data': {
                    'type': 'Feature',
                    'geometry': {
                      'type': 'Polygon',
                      // These coordinates outline Maine.
                      'coordinates': propertys.payload.data.area[y].slots[x].coords.paths
                    },
                    'properties': {
                        'title': 'A'+y+"-"+x
                    }
                  }
                });
                map.addLayer({
                  'id': 'outline' + y + x,
                  'type': 'line',
                  'source': 'maine' + y + x,
                  'layout': {},
                  'paint': {
                    'line-color': propertys.payload.data.area[y].areacolor,
                    'line-width': 3
                  }
                });

                if(y == 0){
                  slotsmap.push({
                    "name": slot.name,
                    "size": slot.size,
                    "unit": slot.unit,
                    "type": slot.type,
                    "coords": {
                      "paths": slot.coords.paths
                    }
                  })
                }


                    // Add a source for the text
                    map.addSource('slot_text' + y + x, {
                      'type': 'geojson',
                      'data': {
                        'type': 'FeatureCollection',
                        'features': [{
                          'type': 'Feature',
                          'geometry': {
                            'type': 'Polygon',
                            // These coordinates outline Maine.
                            'coordinates': slot.coords.paths
                          },
                          'properties': {
                            'title': slot.name
                          }
                        }]
                      }
                    });
                    // Add a symbol layer for the text
                    map.addLayer({
                      'id': 'slot_text' + y + x,
                      'type': 'symbol',
                      'source': 'slot_text' + y + x,
                      'layout': {
                        'text-field': ['get', 'title'],
                        'text-size': 16,
                        'text-offset': [0, 0],
                        'text-anchor': 'top'
                      },
                      'paint': {
                        'text-color': "#fff", // Set the text color to red
                        'text-halo-color': "#000",
                        'text-halo-width': 3
                      }
                    });
              }
            }
          }
        }
        setpropertyselectedareas(slotsmap)
        setpropertyselectedareascopy(slotsmap)
        slotsmapcopy = slotsmap
        });
        
        if (selectarea.areaname) {

          // Initialize MapboxDraw
          draw = new MapboxDraw({
            displayControlsDefault: false,
            modes: {
              ...MapboxDraw.modes
            },
            controls: {
              rectangle: true,
              polygon: true
              // trash: true
            },
            defaultMode: 'draw_polygon',
            styles: [
              // Default style for polygon
              {
                "id": "gl-draw-polygon-fill-inactive",
                "type": "fill",
                "paint": {
                  "fill-color": selectarea.areacolor, // Change this color as needed
                  "fill-outline-color": selectarea.areacolor,
                  "fill-opacity": 0.5
                }
              },
              // Default style for polygon outline
              {
                "id": "gl-draw-polygon-stroke-inactive",
                "type": "line",
                "paint": {
                  "line-color": selectarea.areacolor,
                  "line-dasharray": [2, 2],
                  "line-width": 2,
                  "line-opacity": 0.7
                }
              },
              // vertex point halos
              {
                  "id": "gl-draw-polygon-and-line-vertex-halo-active",
                  "type": "circle",
                  "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                  "paint": {
                      "circle-radius": 7,
                      "circle-color": "#FFF"
                  }
              },
              // vertex points
              {
                  "id": "gl-draw-polygon-and-line-vertex-active",
                  "type": "circle",
                  "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                  "paint": {
                      "circle-radius": 5,
                      "circle-color": selectarea.areacolor,
                  }
              }
            ]
          });
          map.addControl(draw);

          // Store the draw instance in the ref
          drawRef.current = draw;

          var firstpoint = {}
          var previouspoint = {}
      const updateAreadblclick = (e) => {
        // recreatelines()
        if(firstpoint && firstpoint.lat){
          addline([firstpoint.lng, firstpoint.lat], [e.lngLat.lng, e.lngLat.lat])
        }
        previouspoint = {}
        firstpoint = {}
      }
      const updateAreaclick = (e) => {
        console.log(e)
        console.log(previouspoint)
        if(draw && draw.getMode() == 'draw_polygon'){
          
          if(previouspoint && previouspoint.lat){}
          else{
            previouspoint = {lat: e.lngLat.lat, lng: e.lngLat.lng}
            firstpoint = previouspoint
            return
          }
          console.log("previouspoint")
          console.log(previouspoint.lat)
          console.log(previouspoint.lng)
          console.log(e.lngLat.lat)
          console.log(e.lngLat.lng)
          addline([previouspoint.lng, previouspoint.lat], [e.lngLat.lng, e.lngLat.lat])
          previouspoint = e.lngLat
        }
      }

      function addline(current, previous){
        try{
          var linetext = calculateyards(current, previous)
          var newname = new Date().getTime()
          var geojson = {
            "type": "geojson",
            "data": {
              "type": "FeatureCollection",
              "features": [{
                "type": "Feature",
                "geometry": {
                  "type": "LineString",
                  "coordinates": [
                    current,
                    previous
                  ]
                },
                "properties": {
                  "title": linetext + " Yards" // this is a field we can reference in the addLayer with '{title}'
                }
              }]
            }
          };
      
          map.addSource(newname+"_route", geojson);
      
          map.addLayer({
            "id": newname+"_route",
            "type": "line",
            "source": newname+"_route",
            "layout": {
              "line-join": "round",
              "line-cap": "round"
            },
            "paint": {
              "line-color": "transparent",
              "line-width": 18
            }
          });
      
          map.addLayer({
            "id": newname+"_symbols",
            "type": "symbol",
            "source": newname+"_route",
            "layout": {
              "symbol-placement": "line",
              "text-font": ["Open Sans Regular"],
              "text-field": '{title}', // part 2 of this is how to do it
              "text-size": 16
            },
            'paint': {
              'text-color': "#fff", // Set the text color to red
              'text-halo-color': "#000",
              'text-halo-width': 3
            }
          });
        }
        catch(e){

        }
      }

      const onMove = (e) => {
        const coords = e.lngLat;
        setupdatedslotdata(e)
        setupdatedslot(true)
        recreatelines()
        setTimeout(()=>{
          if(e.features[0].geometry.type == 'Polygon'){
            console.log(e.features[0].geometry.coordinates[0].length)
            var previouspoint = []
            for(var x=0;x<e.features[0].geometry.coordinates[0].length;x++){
              if(x==0){
                previouspoint = e.features[0].geometry.coordinates[0][x]
              }
              else{
                console.log(previouspoint)
                console.log(e.features[0].geometry.coordinates[0][x])
                addline(previouspoint, e.features[0].geometry.coordinates[0][x])
                previouspoint = e.features[0].geometry.coordinates[0][x]
              }
            }
          }
        }, 800)
        console.log("slotsmap")
        console.log(slotsmapcopy)
        console.log(propertyselectedareascopy)
      }
      // Function to handle update of area
      const updateArea = (e) => {
        // // Your update area logic here
        const data = draw.getAll();
        setupdateareadata({data: data, e: e})
        console.log("updatearea")
        console.log(previouspoint)
      }
          // Event listeners for draw events
          map.on("click", updateAreaclick);
          map.on("dblclick", updateAreadblclick);
          map.on("draw.create", updateArea);
          map.on("draw.delete", updateArea);
          map.on("draw.update", onMove);

          setMap(map);
          setMapDraw(draw);
          // Clean up on unmount
          return () => {
            map.remove();
            drawRef.current = null; // Reset the draw reference
          };
        }
      }
      else {
        if (propertys.payload.response && propertys.payload.response.data && propertys.payload.response.data.message) {
          toastify({ message: propertys.payload.response.data.message })
          if (propertys.payload.response.data.message == "Invalid token provided!") {
            clearuser()
            userlogout()
            Navigate('/')
            window.location.reload()
          }
        }
      }
    })
  }

  useEffect(() => {
    if(!propertyselectedareas.length){
      if (!initialized.current) {
        initialized.current = true
        getprop()
      }
    }

    // Slot Drag Drop
    if(updatedslotdata && updatedslotdata.features){
      for(var x=0; x<propertyselectedareas.length; x++){
        if(propertyselectedareas[x] && propertyselectedareas[x].id && propertyselectedareas[x].id == updatedslotdata.features[0].id){
          console.log("Slot Drag Drop")
          console.log(updatedslotdata)

          var name = propertyselectedareas[x].name.replace('A', '')
          name = name.split('-')
          name = 'slot_text'+name[1]+name[0]
          console.log("namenamenamenamenamenamename")
          console.log(name)
          console.log(mapObject.getStyle().sources)
          // try{
          //   for(var y=0; y<mapObject.getStyle().layers.length; y++){
          //     if(mapObject.getStyle().layers[y].id == name) {
          //       mapObject.removeLayer(name);
          //     }
          //   }
          //   // mapObject.removeSource(name)
          //   addnameofslot(name[1], name[0], updatedslotdata.features[0].geometry.coordinates[0])
          // }catch(e){}

          const area = turf.area(updatedslotdata.features[0]);
          var areainacres = ((area * 100) / 100) / 4046.86
          const rounded_area = areainacres.toFixed(2);
          let newArr = [...propertyselectedareas];
          
          newArr[x].coords.paths = [updatedslotdata.features[0].geometry.coordinates[0]]
          newArr[x].size = rounded_area
          setpropertyselectedareas(newArr)
          setpropertyselectedareascopy(newArr)
        }
      }

      setupdatedslot(false)
      // console.log("Slot Drag Drop")
      // console.log(propertyselectedareas)
      // console.log(updateareadata)
      // console.log(updatedslotdata)
    }
    // Slot Create and update
    if(updateareadata && updateareadata.data){

      if (updateareadata.data.features.length > 0) {
        const area = turf.area(updateareadata.data);
        var areainacres = ((area * 100) / 100) / 4046.86
        const rounded_area = areainacres.toFixed(2);
        var createname = 0
        var slotcount = document.getElementById('slotscount').getElementsByClassName('propery-detailsdiv')
        if(slotcount.length){
          createname = parseInt(slotcount[slotcount.length-1].getElementsByClassName('p1')[0].innerHTML.split('-')[1])
        }
        setpropertyselectedareas(prevState => ([...prevState, {
            "id": updateareadata.e.features[0].id,
            "name": `A${propertyselectedareaindex+1}-${createname + 1}`,
            "size": rounded_area,
            "unit": "Acres",
            "type": updateareadata.e.features[0].geometry.type,
            "coords": {
              "paths": updateareadata.e.features[0].geometry.coordinates
            }
          }]
        ))
        setpropertyselectedareascopy(prevState => ([...prevState, {
          "name": updateareadata.e.features
        }]
        ))

        // Add Name
        addnameofslot((propertyselectedareaindex+1), (createname + 1), updateareadata.e.features[0].geometry.coordinates)
      };
      setupdateareadata({})
      console.log("Slot Create and update")
    }
    else{
    }
  }, [updatedslot, updateareadata]);

  function setpropertyslots() {
  }

  function addnameofslot(propertyselectedareaindex, createname, coordinates){
    console.log("propertyselectedareaindex")
    console.log(propertyselectedareaindex)
    console.log(createname)
      // Add a source for the text
      mapObject.addSource('slot_text' + propertyselectedareaindex + createname, {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'geometry': {
              'type': 'Polygon',
              // These coordinates outline Maine.
              'coordinates': coordinates
            },
            'properties': {
              'title': `A${propertyselectedareaindex}-${createname}`
            }
          }]
        }
      });
      // Add a symbol layer for the text
      mapObject.addLayer({
        'id': 'slot_text' + (propertyselectedareaindex) + (createname),
        'type': 'symbol',
        'source': 'slot_text' + (propertyselectedareaindex) + (createname),
        'layout': {
          'text-field': ['get', 'title'],
          'text-size': 16,
          'text-offset': [0, 0],
          'text-anchor': 'top'
        },
        'paint': {
          'text-color': "#fff", // Set the text color to red
          'text-halo-color': "#000",
          'text-halo-width': 3
        }
      });
  }

  const changepropertyoutline = (colorId) => {
    const newStyles = mapObjectDraw.options.styles.map(style => {
      return {
        ...style,
        paint: {
          ...style.paint,
          "line-color": colorId,
          "fill-color": colorId
        }
      };
    });

    // Update the draw options with new styles
    mapObjectDraw.options.styles = newStyles;

    // Re-add the control to apply new styles
    mapObject.removeControl(mapObjectDraw);
    mapObject.addControl(mapObjectDraw);
  }

  function handleDeleteclick(e) {
    setdeletearea(property.area[Number(e.target.id.split("_")[1])]._id);
  }

  function editarea(e) {
    console.log("recreatelines")
    recreatelines()
    setpropertyselectedareas([])
    setpropertyselectedareascopy([])
    var areaindex = Number(e.target.id.split("_")[1])
    
    setpropertyselectedareaindex(areaindex)
    changepropertyoutline(property.area[areaindex].areacolor)
    setpropertyselectedarea(property.area[areaindex])
    setOpenIndex(areaindex)
    if (property.area[areaindex].slots && property.area[areaindex].slots.length) {
      var slotsmap = []
      for (var x = 0; x < property.area[areaindex].slots.length; x++) {
        var slot = JSON.parse(JSON.stringify(property.area[areaindex].slots[x]))
        if (property.area[areaindex].slots[x] && property.area[areaindex].slots[x].name) {
          slotsmap.push({
            "name": slot.name,
            "size": slot.size,
            "unit": slot.unit,
            "type": slot.type,
            "coords": {
              "paths": slot.coords.paths
            }
          })
        }
      }
      setpropertyselectedareas(slotsmap)
      setpropertyselectedareascopy(slotsmap)
    }
    setPropertypopup(true);
  }

  const [openIndex, setOpenIndex] = useState(null);

  const toggleSection = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <> <div className="add-main">
      <div className="outline-container">
        <div className="left-side ">
          <div className="map-div slot-prodiv">
            <div className="map_style">
              {mapstyles.map((styles, i) => <div class="form-check">
                <input class="form-check-input" type="radio" name="styledesign" id={"flexRadioDefault1" + styles.value}
                  value={styles.value} onChange={selectmapstyle} checked={styles.value == selectedmapstyles} />
                <label class="form-check-label" for={"flexRadioDefault1" + styles.value}>
                  {styles.name}
                </label>
              </div>)}
            </div>
            <div
              ref={mapContainerRef}
              style={{ width: "100%", height: "700px", borderRadius: "10px" }}
            />
          </div>
        </div>

        <div className="right-side">
          <div className="outline-top">
            <div className="add-top">
              <h2>Property Details</h2>
            </div>
            <div className="pro-detailsdiv">
              <div className="pro-details">
                <p className="th-p">Property Name:</p>
                <p className="td-p">{property.title}</p>
              </div>

              <div className="pro-details">
                <p className="th-p">Category:</p>
                <p className="td-p">{property.category}</p>
              </div>
              <div className="pro-details">
                <p className="th-p">City:</p>
                <p className="td-p">{property.city}</p>
              </div>
              <div className="pro-details">
                <p className="th-p">County/Parish:</p>
                <p className="td-p">{property.county}</p>
              </div>
              <div className="pro-details">
                <p className="th-p">State:</p>
                <p className="td-p">{property.state}</p>
              </div>
              <div className="pro-details">
                <p className="th-p">Country:</p>
                <p className="td-p">{property.country}</p>
              </div>
            </div>
          </div>
          <div className="outline-top outline-bottom">
            <div className="add-top">
              <h2>Selected Area Details</h2>
            </div>

            <div className="pro-detailsdiv">
              <div className="pro-details">
                <p className="th-p">Address:</p>
                <p className="td-p">{property.areaaddress}</p>
              </div>

              <div className="pro-details">
                <p className="th-p">Total Area:</p>
                <p className="td-p">{property.totalarea} {property.totalareaunit}</p>
              </div>
              <div className="pro-details">
                <p className="th-p">Coordinates:</p>
                <p className="td-p">Latitude {property && property.latlng ? property.latlng.lat : ""} & Longitude {property && property.latlng ? property.latlng.lng : ""}</p>
              </div>
            </div>
          </div>

          <button className="btn-slot-pro" onClick={handleGoback}>
            <AddIcon /> Add New Area
          </button>

          <div className="add-main view-slot accordion">
            {property && property.area ? property.area.map((areas, i) => {
              return (
                <div key={i} className="add-formdiv accordion-section">
                  <div className="add-top">
                    <div className="slot-datetable">
                      <div className="slot-datetable1" >
                        <h2>
                          {areas.areaname}
                          </h2>
                      </div>
                      <div className="slot-datetable2">
                        <img src={Editicon} alt="Editicon" onClick={()=>gotoeditarea(i)} />
                        <img src={Deleteicon} alt="Deleteicon" id={"propertyareadelete_" + i} onClick={handleDeleteclick} />
                        <img src={Areaimg} alt="Areaimg" id={"propertyarea_" + i} onClick={editarea} />
                        <svg  className={openIndex === i?"arrow_open":"arrow_close"} onClick={() => toggleSection(i)} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff"><path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"/></svg>
                      </div>
                    </div>
                  </div>
                
                {openIndex === i && (
                  <>
                  <div className="add-form">
                    <div className="add-table">
                      <table className="table ">
                        <thead>
                          <tr>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col">Price($)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {areas.dates.map((date, di) => {
                            return (
                              <tr>
                                {/* <td>{date.start.substring("0", "10")}</td> */}
                                <td>{convertdate(date.start)}</td>
                                {/* <td>{date.end.substring("0", "10")}</td> */}
                                <td>{convertdate(date.end)}</td>
                                <td>{date.price}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="add-form">
                    <div className="add-table">
                      <table className="table ">
                        <thead>
                          <tr>
                            <th scope="col">Slot</th>
                            <th scope="col">Size (in Acres)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {areas.slots && areas.slots.length ? areas.slots.map((slot, si) => {
                            return (
                              <tr>
                                <td>{slot.name}</td>
                                <td>{slot.size} {slot.unit}</td>
                              </tr>
                            )
                          }) : <p className="header-empty">No Slot created yet!</p>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  </>
                )}
                </div>)
            }) : null}
          </div>

          <div className="slot-btndiv">
            <button className="btn-add-back" onClick={handleGoback}>Go Back</button>
            <button className="add-btn" onClick={handleSave}>
              Save & Continue
            </button>
          </div>
        </div>
      </div>
    </div>
      {propertypopup && propertyselectedarea && propertyselectedarea.areaname ? (
         <Draggable
         handle=".handle"
         defaultPosition={{x: 0, y: 0}}
         position={null}
         grid={[25, 25]}
         scale={1}>
          <div className="property-popup handle">
          <div className="property-content">
            <div className="property-top">
              <h1>{propertyselectedarea.areaname}</h1>
            </div>
            <div className="property-content-inner" id="slotscount">
              {propertyselectedareas.length ?
                propertyselectedareas.map((slot, i) => {
                  return (
                    <div className="propery-detailsdiv">
                      <p className="p1">{slot.name}</p>
                      <p className="p2">{slot.size} {slot.unit}</p>
                      <div className="img-div">
                        <img src={Vectoricon} alt="Vectoricon" id={"slotcopy_" + i} onClick={duplicatePolygon} />
                        <img src={Deleteicon} alt="Deleteicon" id={"slotdelete_" + i} onClick={deletePolygon} />
                      </div>
                    </div>
                  )
                }) : <p className="header-empty">Start using Map tools to create slots</p>}
            </div>
            <div className="property-btn">
              {/* <button className="btn1" onClick={handleCanclepopup}>Cancel</button> */}
              <button className="btn2" onClick={handleSavepopup}>Save</button>
            </div>
          </div>
        </div></Draggable>
      ) : null}
      {deletearea && (
        <div className="managecou-popup">
          <div className="managecou-content">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure Want to Delete?</h1>
              <p>Are you sure you want to delete this Area? Please note that this action cannot be undone.</p>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                Cancel
              </button>
              <button className="btn2" onClick={handlepopupclosedelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
