import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import React, { useState, useEffect } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout } from '../redux/controller';
import 'react-toastify/dist/ReactToastify.css';
import Deleteicon from "../assets/trash.png";
import Editicon from "../assets/pencil.png";
import Xicon from "../assets/Delete Icon.svg"

export default function Hunters() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [managehunterstatus, setManagehunterstatus] = useState(false);
  const [totalHunters, setTotalHunter] = useState(0);
  const [selectedproperty, setSelectedproperty] = useState('');
  const [status, setStatus] = useState('Loading...');
  const [Reservationsdata, setReservationsdata] = useState([]);
  const [Reservationsdatacopy, setReservationsdatacopy] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const usertoken = localStorage.getItem('usertoken');
  const [searchvalue, setsearchvalue] = useState("");

  const [perpage, setperpage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  function inputperPage(e){
    setperpage(e.target.value)
  }
  function handlepopupclose() {
    setManagehunterstatus(!managehunterstatus);
  }
  async function handlepopupclosedelete() {

    console.log(selectedproperty)
    var response = await dispatch(fetchData({
      "apiurl": "/auth/user/deletehunter",
      hunterid: selectedproperty,
      headers: { "x-access-token": usertoken }
    }))
    console.log(response)
    if (response && response.payload && response.payload.status == 200) {
      setManagehunterstatus(!managehunterstatus);
      toastify({message: "Hunter Deleted Successfully"}) 
      getAllHunters("", 1)
    }
    else{
      setManagehunterstatus(!managehunterstatus);
      toastify({message: "Not able to delete hunter"})
    }
  }
  const getAllHunters = async (searchQuery = "", page = 1) => {

    setReservationsdata([]);
    setStatus('Loading...');
    var hunterlist = await dispatch(
      fetchData({
        // apiurl: "/auth/user/allhunters",
        apiurl: '/reservation/hunter/review/list',
        page: page,
        limit: 10,
        search: searchQuery,
        headers: { "x-access-token": usertoken }
      })
    );
console.log(hunterlist.payload)
    if (hunterlist && hunterlist.payload && hunterlist.payload.status === 200) {

      setReservationsdata([]);
      if (hunterlist.payload.totalHunters === 0) {
        setStatus('No Hunter!');
        setCurrentPage(1)
        setTotalHunter(0)
      } else {
        const huntersListLocal = hunterlist.payload.data.map((hunters) => ({
          id: hunters.hunter._id,
          hunter_name: hunters.hunter.firstname + ' ' + hunters.hunter.lastname,
          email: hunters.hunter.email ?? '--',
          phone_number: hunters.hunter.phonenumber ?? '--',
          number_of_reservations: hunters.number_of_reservations,
          subscriptions: hunters.hunter.subscriptions,
          action: hunters.hunter.creditCard != null ? (hunters.hunter.creditCard.cardNumber != null ? 'Completed' : 'Pending') : 'Pending',
          createdAt: hunters.hunter.createdAt ?? '--'
        }));
        setReservationsdata(huntersListLocal);
        setReservationsdatacopy(huntersListLocal);
        setTotalHunter(hunterlist.payload.totalHunters);
        if(hunterlist.payload.data.length){
          setStatus('');
        }
        else{
          setStatus('No Hunter!');
        }
      }
    } else if (hunterlist.payload.response && hunterlist.payload.response.data && hunterlist.payload.response.data.message) {
      if (hunterlist.payload.response.data.message === "Invalid token provided!") {
        toastify({ message: 'Session expired' });
        userlogout();
        navigate('/');
      }
    } else {
      setStatus('Error fetching hunters.');
    }
  };

  useEffect(() => {
    getAllHunters(searchInput, currentPage);
  }, []);

  function handleDeleteclick(index) {
    if (index.target.id && index.target.id.split("_")[1]) {
      setSelectedproperty(index.target.id.split("_")[1])
      setManagehunterstatus(true);
    }
  }

  const handleEdit = (e) => {
    // console.log(arrayDataItems)
    // if (e.target.id.split("_")[1]) {
    //   Navigate("/propertyreview?index=" + e.target.id.split("_")[1]);
    // }
  };

  const handlePageClick = (selectedPage) => {
    console.log("Selected page ", selectedPage.selected + 1);
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleSearch = () => {

    setReservationsdata([]);
    setStatus('Loading...');
    getAllHunters(searchvalue, 1);
    setCurrentPage(1);
  };

  const handleAddHunter = () => {
    navigate("/newhunter")
  };

  const handleClearSearch = () => {
    setReservationsdata([]);
    setStatus('Loading...');
    setSearchInput("")
    setsearchvalue("");
    getAllHunters("", 1)
    setCurrentPage(1)
    setTotalHunter(0)
  };
  const inputsearchHandler = (e) => {
    console.log(e.target.name);
    setsearchvalue(e.target.value);
  };

  const searchfilterhandler = (e) => {
      if(searchvalue){
        setCurrentPage(1);
        var huntlist = []
        for(var x=0; x<Reservationsdatacopy.length; x++){
          if(Reservationsdatacopy[x].email.toLowerCase().includes(searchvalue.toLowerCase()) || Reservationsdatacopy[x].hunter_name.toLowerCase().includes(searchvalue.toLowerCase())){
            huntlist.push(Reservationsdatacopy[x])
          }
          else if(Reservationsdatacopy[x].phone_number && Reservationsdatacopy[x].phone_number.toLowerCase().includes(searchvalue.toLowerCase())){
            huntlist.push(Reservationsdatacopy[x])
          }
        }
        console.log(Reservationsdatacopy)
        console.log(huntlist)
        setReservationsdata(huntlist);
        if(huntlist && huntlist.length){
          setStatus("")}
        else{
          setStatus("No Hunter!")
        }
      }
      else{
        setReservationsdata(Reservationsdatacopy);
      }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchfilterhandler(event);
      // setsearchvalue(event.target.value);
    }
  };

  return (
    <div className="hunter-main">
      <div className="search-div">
        <div className="input-wrapper">
          <input
            type="text"
            className="form-control hunter-search"
            placeholder="Hunter Name, Email Address, Phone Number"
            onKeyDown={handleKeyDown}
            value={searchvalue}
            // onChange={(e) => setSearchInput(e.target.value)}
            onChange={inputsearchHandler}
            onPaste={inputsearchHandler}
          />
          {searchvalue && (
            <button className="clear-btn" onClick={handleClearSearch}>×</button>
          )}
        </div>
        {/* <button className="add-btn" onClick={handleSearch}>Search</button> */}
        <button className="add-btn" onClick={searchfilterhandler}>Search</button>
      </div>

      <div className="managecoupons-table add-form">
        <div className="add-table">
          <table className="table">
            <thead>
              <tr>
                <th>Hunter Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Total Reservations</th>
                <th>Membership Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Reservationsdata.length === 0 ?
                <td colSpan="5" style={{ textAlign: 'center' }}>
                  <div style={{
                    paddingLeft: '10px',
                    paddingTop: '40px',
                    display: 'inline-block',
                  }}>
                    {status}
                  </div>
                </td>
                :
                Reservationsdata.map((reservation, ind) => (
                  ind<perpage*currentPage && ind>=perpage*(currentPage-1)?
                  <tr
                    key={reservation.id}
                    className={
                      reservation.action === "Pending"
                        ? "Action_cancelled"
                        : reservation.action === "Completed"
                          ? "Action_completed"
                          : ""
                    }
                  >
                    <td>
                      <Link
                        className="hunter-link"
                        to={`/huntersdetails`}
                        state={reservation}
                        style={{
                          textDecoration: 'underline',
                          color: '#be7637'
                        }}
                      >{reservation.hunter_name}</Link>
                    </td>
                    <td>{reservation.email}</td>
                    <td>{reservation.phone_number}</td>
                    <td>
                      <Link
                        className="hunter-link"
                        to={`/huntersdetails`}
                        state={reservation}
                        style={{
                          textDecoration: 'underline',
                          color: '#be7637'
                        }}
                      >
                        {reservation.number_of_reservations}
                      </Link>
                    </td>
                    <td>
                      {reservation&&reservation.subscriptions&&reservation.subscriptions.length&&reservation.subscriptions[0].no_of_memberships?"Membership" :"Direct"}
                      {/* <button
                        className="reservation-actionbtn"
                        onClick={() =>
                          reservation.action.toLowerCase() === "Cancelled"
                        }
                      >
                        <i class={
                          reservation.action === "Completed"
                            ? "fa-solid fa-circle-check"
                            : "fa-regular fa-clock"
                        }></i>
                        {reservation.action}
                      </button> */}

                    </td>
                    <td>
                      <img src={Deleteicon} alt="Deleteicon"  id={"deletehunter_" + reservation.id} onClick={handleDeleteclick} className="delete-icon"/>
                    </td>
                  </tr>
                  :''
                ))}
            </tbody>
          </table>
        </div>
      {managehunterstatus && (
        <div className="managecou-popup">
          <div className="managecou-content">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure Want to Delete?</h1>
              <p>This will result in the deletion of all reservations associated with this Hunter.</p>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                Cancel
              </button>
              <button className="btn2" onClick={handlepopupclosedelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="rowperpage">
        <div className="perpagecount">
          Row Per Page&nbsp;
          <select
            onChange={inputperPage}
            value={perpage}>
            <option value={10}>10</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={150}>150</option>
            <option value={200}>200</option>
          </select>
        </div>
         <ReactPaginate
          pageCount={Math.ceil(Reservationsdata.length / perpage)}
          pageRangeDisplayed={5}
          breakLabel={"..."}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination custom-pagination"}
          activeClassName={"active"}
        />
      </div>
      </div>
    </div>
  );
}
