import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { useNavigate, Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import { toastify, userlogout, clearuser } from "../redux/controller";
import Deleteicon from "../assets/trash.png";
import Xicon from "../assets/Delete Icon.svg"
import {format} from 'date-fns';

const colors = {
  orange: "#FFA500",
  grey: "#a9a9a9"
};

export default function Huntersreview() {
  let Navigate = useNavigate();
  const dispatch = useDispatch()
  const initialized = useRef(false)
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setloading] = useState(false);

  const [currentValue, setCurrentValue] = useState(0);
  const [searchvalue, setsearchvalue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [starrating, setstarrating] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const perPage = 5; // Number of items per page
  const [reviewstatus, setReviewstatus] = useState(false);
  const [selectedhunter, setselectedhunter] = useState({});
  const [hunterlist, sethunterlist] = useState([]);
  const [copyhunterlist, copysethunterlist] = useState([]);

  const [perpage, setperpage] = useState(10);
  
  const [selectedproperty, setSelectedproperty] = useState('');
  const [managehunterstatus, setManagehunterstatus] = useState(false);
  const [stepinputField, setstepInputField] = useState({
    description: "",
    rating: ""
  });
  const stars = Array(5).fill(0);
  const usertoken = useSelector((state) => state.userdata.usertoken);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      if (usertoken) {
        getreviewlist()
      }
    }
  }, []);

  function inputperPage(e){
    setperpage(e.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // handleSearch();
      setsearchvalue(event.target.value);
    }
  };

  const inputsearchHandler = (e) => {
    console.log(e.target.name);
    setsearchvalue(e.target.value);
  };

  const searchfilterhandler = (e) => {
      if(searchvalue){
        setCurrentPage(1)
        // var huntlist = copyhunterlist.filter(e=>e.hunter.email.toLowerCase().includes(searchvalue.toLowerCase())||(e.hunter.firstname.toLowerCase()+e.hunter.lastname.toLowerCase()).includes(searchvalue.toLowerCase().replaceAll(' ', ''))||e.hunter.phonenumber?e.hunter.phonenumber.toLowerCase().includes(searchvalue.toLowerCase()):null)
        // sethunterlist(huntlist)

        var huntlist = []
        for(var x=0; x<copyhunterlist.length; x++){
          var name = copyhunterlist[x].hunter.firstname+" "+copyhunterlist[x].hunter.lastname
          if(copyhunterlist[x].hunter.email.toLowerCase().includes(searchvalue.toLowerCase()) || name.toLowerCase().includes(searchvalue.toLowerCase())){
            huntlist.push(copyhunterlist[x])
          }
          else if(copyhunterlist[x].hunter.phonenumber && copyhunterlist[x].hunter.phonenumber.toLowerCase().includes(searchvalue.toLowerCase())){
            huntlist.push(copyhunterlist[x])
          }
        }
          console.log(huntlist)
          sethunterlist(huntlist);
      }
      else{
        sethunterlist(copyhunterlist)
      }
  }

  const inputsHandler = (e) => {
    console.log(e.target.name);
    setstepInputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  async function getreviewlist(){
    if (usertoken == null || usertoken.length < 1) {
      clearuser()
      userlogout()
      Navigate('/')
      window.location.reload()
      return
    }

    setloading(true)
    var reviewlist = await dispatch(
      fetchData({
        apiurl: "/reservation/hunter/review/list",
        headers: { "x-access-token": usertoken }
      })
    )
    console.log(reviewlist)
    setloading(false)
    if (reviewlist && reviewlist.payload && reviewlist.payload.status == 200) {
      sethunterlist(reviewlist.payload.data)
      copysethunterlist(reviewlist.payload.data)
    }
    else{
      sethunterlist([])
      copysethunterlist([])
      if (reviewlist.payload.response && reviewlist.payload.response.data && reviewlist.payload.response.data.message) {
        if (reviewlist.payload.response.data.message === "Invalid token provided!") {
          toastify({ message: 'Session expired' });
          userlogout();
          Navigate('/');
        }
      }
    }
  }

  function handlepopupclose() {
    setManagehunterstatus(!managehunterstatus);
  }

  async function handlepopupclosedelete() {
    console.log(selectedproperty)
    var response = await dispatch(fetchData({
      "apiurl": "/auth/user/deletehunter",
      hunterid: selectedproperty,
      headers: { "x-access-token": usertoken }
    }))
    console.log(response)
    if (response && response.payload && response.payload.status == 200) {
      setManagehunterstatus(!managehunterstatus);
      toastify({message: "Hunter Deleted Successfully"}) 
      getreviewlist()
    }
    else{
      setManagehunterstatus(!managehunterstatus);
      toastify({message: "Not able to delete hunter"})
    }
  }

  // Function to handle page change
  const handlePageClick = (selectedPage) => {
    console.log(selectedPage);
    setCurrentPage(selectedPage.selected+1);
  };

  // Calculate start and end index for the current page
  const startIndex = currentPage * perPage;
  const endIndex = startIndex + perPage;

  // Get data for the current page
  const currentData = hunterlist.slice(startIndex, endIndex);

  const handlereviewClick = (data) => {
    setselectedhunter(hunterlist[parseInt(data.target.id.split('_')[1])].hunter)
    if(hunterlist[parseInt(data.target.id.split('_')[1])].hunter.review && hunterlist[parseInt(data.target.id.split('_')[1])].hunter.review.length){
      setstarrating(hunterlist[parseInt(data.target.id.split('_')[1])].hunter.review[0].rating)
      setstepInputField((prevState) => ({
        ...prevState,
        ['description']: hunterlist[parseInt(data.target.id.split('_')[1])].hunter.review[0].description
      }));
    }
    else{
      setstarrating(0)
      setstepInputField((prevState) => ({
        ...prevState,
        ['description']: ''
      }));
    }
    setReviewstatus(data)
    setPopupOpen(true);
  };
  const handlestarClick = (data) => {
    setstarrating(data)
  }

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const handleEdit = () => {
    // Navigate("/createcoupon");
  };

  const handleWriteReviewClick = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleClearSearch = () => {
    setsearchvalue("");
    getreviewlist();
  };

  function handleDeleteclick(index) {
    if (index.target.id && index.target.id.split("_")[1]) {
      setSelectedproperty(index.target.id.split("_")[1])
      setManagehunterstatus(true);
    }
  }

  function convertdate(date) {
    return date.substring(6, 8) + "/" + date.substring(9, 11) + "/" + date.substring(1, 5)
  }
  
  const handleSubmitPopup = async() => {
    var reviewlist = await dispatch(
      fetchData({
        apiurl: "/reservation/hunter/review/update",
        description: stepinputField.description,
        rating: starrating,
        hunterid: selectedhunter._id,
        headers: { "x-access-token": usertoken }
      })
    )
    console.log(reviewlist)
    if (reviewlist && reviewlist.payload && reviewlist.payload.status == 200) {
      getreviewlist()
      setstarrating(0)
      setstepInputField((prevState) => ({
        ...prevState,
        ['description']: ''
      }));
    }
    else{
      if (reviewlist.payload.response && reviewlist.payload.response.data && reviewlist.payload.response.data.message) {
        toastify({ message: reviewlist.payload.response.data.message })
      }
      if(reviewlist.payload.response.data.message == "Invalid token provided!"){
        clearuser()
        userlogout()
        Navigate('/')
        window.location.reload()
      }
    }
    setPopupOpen(false);
  };

  return (
    <>
    <div className="hunter-main">
      <div className="search-div">
        <div className="input-wrapper">
          <input
            type="text"
            className="form-control hunter-search"
            placeholder="Hunter Name, Email Address, Phone Number"
            value={searchvalue}
            onChange={inputsearchHandler}
            name="searchvalue"
            onKeyDown={handleKeyDown}
          />
          {searchvalue && (
            <button className="clear-btn" onClick={handleClearSearch}>×</button>
          )}
        </div>
        <button className="add-btn" onClick={searchfilterhandler}>Search</button>
      </div>
      <div className="add-form pagination-table">
        <div className="add-table">
          <table className="table ">
            <thead>
              <tr>
                <th scope="col">Hunter Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Account Created</th>
                <th scope="col">Rating/Ranking</th>
                <th scope="col">Review</th>
              </tr>
            </thead>
            <tbody  style={{ display: hunterlist && hunterlist.length ? 'contents':   'table-caption'}}>
              {hunterlist && hunterlist.length ? hunterlist.map((item, index) => (
                  index<perpage*currentPage && index>=perpage*(currentPage-1)?
                <tr key={item.hunter.id}>
                  <td>{item.hunter.firstname} {item.hunter.lastname}</td>
                  <td>{item.hunter.email}</td>
                  <td>{item.hunter.phonenumber}</td>
                  <td>{item.hunter.createdAt?convertdate(JSON.stringify(item.hunter.createdAt)):''}</td>
                  <td>
                    <Link id={'writereview_'+index} onClick={handlereviewClick}>Write a review</Link>
                  </td>
                  <td >
                    {[...Array(5)].map((_, index) => (
                      
                      <FaStar
                        key={index}
                        size={24}
                        color={(item.hunter.review && item.hunter.review.length && item.hunter.review[0].rating ? item.hunter.review[0].rating > index : false) ? colors.orange : colors.grey}
                      />
                    ))}
                  
                  </td>
                  {/* <td>
                    <img src={Deleteicon} alt="Deleteicon"  id={"deletehunter_" + item._id} onClick={handleDeleteclick} className="delete-icon"/>
                  </td> */}
                </tr>
                  :''
              )): loading?<p className="header-empty">Loading...</p>:<p className="header-empty">No Hunter! </p>}
            </tbody>
          </table>
        </div>
      {managehunterstatus && (
        <div className="managecou-popup">
          <div className="managecou-content">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure Want to Delete?</h1>
              <p>Are you sure you want to delete this Hunter? Please note that this action cannot be undone.</p>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                Cancel
              </button>
              <button className="btn2" onClick={handlepopupclosedelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="rowperpage">
        <div className="perpagecount">
          Row Per Page&nbsp;
          <select
            onChange={inputperPage}
            value={perpage}>
            <option value={10}>10</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={150}>150</option>
            <option value={200}>200</option>
          </select>
        </div>
        <ReactPaginate
          pageCount={Math.ceil(hunterlist.length / perpage)}
          pageRangeDisplayed={5}
          breakLabel={"..."}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination custom-pagination"}
          activeClassName={"active"}
        />
      </div>
      </div>

      <div className="hunter-popup">
        {popupOpen && (
          <div className="popup">
            <div className="popup-content">
              <i className="fa-solid fa-xmark" onClick={handleClosePopup}></i>

              {reviewstatus==true ? <h2>Review</h2>:<h2>Write A Review</h2>}
              <div className="start-div">
                {stars.map((_, index) => {
                  return (
                    <FaStar
                      key={index}
                      size={24}
                      onClick={() => handlestarClick(index + 1)}
                      onMouseOver={() => handleMouseOver(index + 1)}
                      onMouseLeave={handleMouseLeave}
                      color={
                        starrating > index
                          ? colors.orange
                          : colors.grey
                      }
                      style={{
                        marginRight: 10,
                        cursor: "pointer"
                      }}
                    />
                  );
                })}
              </div>
              <textarea
                rows="4"
                cols="50"
                placeholder="Add your review..."
                name="description"
                onChange={inputsHandler}
                value={stepinputField.description}
              ></textarea>
              <div className="hunter-btndiv">
                <button className="cancel" onClick={handleClosePopup}>
                  Cancel
                </button>
                <button className="post" onClick={handleSubmitPopup}>
                  Post
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      </div>
    </>
  );
}
