import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Xicon from "../assets/Delete Icon.svg";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import { toastify, userlogout, clearuser, dateToMDY } from "../redux/controller";


export default function Reservations() {
  let Navigate = useNavigate();
  const dispatch = useDispatch()
  const initialized = useRef(false)
  const [activeTab, setActiveTab] = useState("upcoming");
  const [upcomingreservationid, setUpcomingreservationid] = useState(true);
  const [historyreservationid, setHistoryreservationid] = useState(true);
  const [upcomingcurrentPage, setUpcomingcurrentPage] = useState(1);
  const [upcomingperPage, setupcomingperPage] = useState(10);
  const [historycurrentPage, setHistorycurrentPage] = useState(1);
  const [historyperPage, sethistoryperPage] = useState(10);
  const [upcomingreservationlist, setupcomingreservationlist] = useState([]);
  const [upcomingreservationcount, setupcomingreservationcount] = useState(0);
  const [historyreservationlist, sethistoryreservationlist] = useState([]);
  const [historyreservationcount, sethistoryreservationcount] = useState(0);
  const [showCancelLoader, setCancelLoader] = useState(false);
  const [cancellationreason, setcancellationreason] = useState("");
  const [cancellationid, setcancellationid] = useState("");
  const [loading, setloading] = useState(false);

  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: ""
  });

  const [step1inputField, setstep1InputField] = useState({
    cancelreason: ""
  })

  const [inputupcoming, setinputupcoming] = useState({
    search: ""
  })

  const [inputhistory, setinputhistory] = useState({
    search: ""
  })

  const [cancelreservation, setcancelreservation] = useState({});

  function handlepopupclose() {
    setcancelreservation({});
  }

  function sortreservations(type) {
    let direction = "ascending";
    if (sortConfig.key === type && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    console.log("type")
    console.log(type)
    console.log(direction)
    setSortConfig({ key: type, direction });
  }

  async function handlecancelreservation() {
    setcancelreservation({});
    setCancelLoader(true)
    var cancelledreservation = await dispatch(fetchData({
      apiurl: "/reservation/cancel",
      reservationid: cancelreservation._id,
      cancellationreason: step1inputField.cancelreason,
      headers: { "x-access-token": usertoken }
    }))
    console.log(cancelledreservation)
    setCancelLoader(false)
    setstep1InputField((prevState) => ({
      ...prevState,
      ['cancelreason']: ''
    }));
    if (cancelledreservation && cancelledreservation.payload && cancelledreservation.payload.status == 200) {

      var cancelledreservationnotification = await dispatch(fetchData({
        apiurl: "/reservation/send/notification",
        "messagingtoken": cancelreservation.userid.udid,
        "title": "Landowner cancelled your reservation",
        "message": cancelreservation.cart[0].property.title + " : " + step1inputField.cancelreason,
        "propertyname": cancelreservation.cart[0].property.title,
        headers: { "x-access-token": usertoken }
      }))

      getreservations()
    }
    else {
      console.log(cancelledreservation.payload)
      if (cancelledreservation.payload.response && cancelledreservation.payload.response.data && cancelledreservation.payload.response.data.message) {
        toastify({ message: cancelledreservation.payload.response.data.message })
        if (cancelledreservation.payload.response.data.message == "Invalid token provided!") {
          clearuser()
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
      else if (cancelledreservation.payload && cancelledreservation.payload.message) {
        toastify({ message: cancelledreservation.payload.message })
      }
      return null
    }
  }


  function handleCancelclick() {
    setcancelreservation({});
  }

  const usertoken = useSelector((state) => state.userdata.usertoken);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      if (usertoken) {
        getreservations()
      }
    }
  }, []);

  function getreservations() {
    upcomingreservation({
      apiurl: "/reservation/upcoming",
      page: upcomingcurrentPage,
      limit: upcomingperPage,
      headers: { "x-access-token": usertoken }
    })
    historyreservation({
      apiurl: "/reservation/history",
      page: historycurrentPage,
      limit: historyperPage,
      headers: { "x-access-token": usertoken }
    })
  }


  const getCurrentPageData = () => {
    console.log(upcomingreservationlist)
    const filteredData = upcomingreservationlist.sort((a, b) => {
      switch (sortConfig.key) {
        case "hunter_name":
          return sortConfig.direction === "ascending"
            ? a.hunter_name.localeCompare(b.hunter_name)
            : b.hunter_name.localeCompare(a.hunter_name);
        case "landowner_name":
          return sortConfig.direction === "ascending"
            ? a.landowner_name.localeCompare(b.landowner_name)
            : b.landowner_name.localeCompare(a.landowner_name);
        case "landowner_name":
          return sortConfig.direction === "ascending"
            ? a.property_name.localeCompare(b.property_name)
            : b.property_name.localeCompare(a.property_name);
        case "createdAt":
          return sortConfig.direction === "ascending"
            ? new Date(a.createdAt) -
            new Date(b.createdAt)
            : new Date(b.createdAt) -
            new Date(a.createdAt);
        default:
          return 0;
      }
    });
    console.log('filteredData')
    console.log(filteredData)
    // const startIndex = (currentPage - 1) * itemsPerPage;
    // const endIndex = startIndex + itemsPerPage;
    return filteredData;
  };

  async function upcomingreservation(body) {
    setloading(true)
    var upcomingreservationlist = await dispatch(
      fetchData(body)
    )
    console.log(upcomingreservationlist)
    if (upcomingreservationlist.payload.status == 200) {
      setupcomingreservationlist(upcomingreservationlist.payload.data)
      setloading(false)
      setupcomingreservationcount(upcomingreservationlist.payload.count)
    }
    else {
      setupcomingreservationlist([])
      setloading(false)
      if (upcomingreservationlist.payload.response && upcomingreservationlist.payload.response.data && upcomingreservationlist.payload.response.data.message) {
        toastify({ message: upcomingreservationlist.payload.response.data.message })
      }
      if (upcomingreservationlist.payload.response && upcomingreservationlist.payload.response.data && upcomingreservationlist.payload.response.data.message == "Invalid token provided!") {
        clearuser()
        userlogout()
        Navigate('/')
        window.location.reload()
      }
    }
  }

  async function historyreservation(body) {
    setloading(true)
    var historyreservationlist = await dispatch(
      fetchData(body)
    )
    console.log(historyreservationlist)
    if (historyreservationlist.payload.status == 200) {
      sethistoryreservationlist(historyreservationlist.payload.data)
      setloading(false)
      sethistoryreservationcount(historyreservationlist.payload.count)
    }
    else {
      sethistoryreservationlist([])
      setloading(false)
      if (historyreservationlist.payload.response && historyreservationlist.payload.response.data && historyreservationlist.payload.response.data.message) {
        toastify({ message: historyreservationlist.payload.response.data.message })
      }
      if (historyreservationlist.payload.response && historyreservationlist.payload.response.data && historyreservationlist.payload.response.data.message == "Invalid token provided!") {
        clearuser()
        userlogout()
        Navigate('/')
        window.location.reload()
      }
    }
  }

  const handlePageClick = (selectedPage) => {
    // setupcomingreservationlist([])
    setUpcomingcurrentPage(selectedPage.selected + 1);
    upcomingreservation({
      apiurl: "/reservation/upcoming",
      page: selectedPage.selected + 1,
      limit: upcomingperPage,
      headers: { "x-access-token": usertoken }
    })
  };

  const inputsHandler = (e) => {
    console.log(e.target.name);
    setstep1InputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };
  // Calculate start and end index for the current page
  const upcomingstartIndex = upcomingcurrentPage * upcomingperPage;
  const upcomingendIndex = upcomingstartIndex + upcomingperPage;

  const handlehistoryPageClick = (selectedPage) => {
    // sethistoryreservationlist([])
    setHistorycurrentPage(selectedPage.selected + 1);
    historyreservation({
      apiurl: "/reservation/history",
      page: selectedPage.selected + 1,
      limit: historyperPage,
      headers: { "x-access-token": usertoken }
    })
  };

  // Calculate start and end index for the current page
  const historystartIndex = historycurrentPage * historyperPage;
  const historyendIndex = historystartIndex + historyperPage;

  function inputupcomingperPage(e) {
    setUpcomingcurrentPage(1)
    setupcomingperPage(parseInt(e.target.value))
    upcomingreservation({
      apiurl: "/reservation/upcoming",
      page: upcomingcurrentPage,
      limit: parseInt(e.target.value),
      headers: { "x-access-token": usertoken }
    })
  }

  function inputhistoryperPage(e) {
    setHistorycurrentPage(1)
    sethistoryperPage(parseInt(e.target.value))
    historyreservation({
      apiurl: "/reservation/history",
      page: historycurrentPage,
      limit: parseInt(e.target.value),
      headers: { "x-access-token": usertoken }
    })
  }

  const handleClearSearch = () => {
    setinputupcoming((prevState) => ({
      ...prevState,
      ['search']: ''
    }));
    setUpcomingcurrentPage(1)
    getreservations()
  }

  function handleHistoryReservations() {
    setHistoryreservationid(!historyreservationid);
  }

  function handleinputupcomings(e) {
    setinputupcoming((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }

  function convertdate(date) {
    return date.substring(6, 8) + "/" + date.substring(9, 11) + "/" + date.substring(1, 5)
  }
  function handleinputupcoming() {
    upcomingreservation({
      apiurl: "/reservation/upcoming",
      page: upcomingcurrentPage,
      limit: upcomingperPage,
      search: inputupcoming.search,
      headers: { "x-access-token": usertoken }
    })
  }

  function handleinputhistorys(e) {
    setinputhistory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }

  function gotopropertydetail(reservation, today) {
    console.log(reservation)
    var element = reservation.cart[0]
    var url = today ? "/propertydetailsmap?index=" + reservation._id + "&all=" + element.property._id : "/propertydetailsmap?index=" + reservation._id
    Navigate(url, {
      state: {
        property: element.property,
        id: element.property.id,
        property_name: element.property.title,
        category: element.property.category,
        stateLocal: element.property.state,
        county: element.property.country,
        city: element.property.city,
        zipcode: element.property.zipcode,
        totalarea: element.property.totalarea,
        action: element.property.approved == null ? 'Pending' : element.property.approved === "false" ? 'Rejected' : element.property.approved === "paused" ? 'Paused' : 'Approved',
        landownerId: element?.property.userid
      }
    })
  }

  const handleClearSearchistory = () => {
    setinputhistory((prevState) => ({
      ...prevState,
      ['search']: ''
    }));
    setHistorycurrentPage(1)
    getreservations()
  }
  const handleEditClick = (reservationdetails) => {
    Navigate(`/reservationdetails/${reservationdetails._id}`, { state: { reservationdetails } });
  };

  function handleinputhistory() {
    historyreservation({
      apiurl: "/reservation/history",
      page: historycurrentPage,
      limit: historyperPage,
      search: inputhistory.search,
      headers: { "x-access-token": usertoken }
    })
  }

  const handleKeyDown = (event) => {
    console.log("handleKeyDown")
    console.log(event.key)
    console.log(event.target.value)
    handleinputupcoming()
  };

  const handleKeyDownh = (event) => {
    if (event.key === 'Enter') {
      // handleSearch();
      handleinputhistory()
    }
  };

  return (
    <>
      <div>
        {activeTab === "upcoming" ? (
          <div className="search-div">
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control hunter-serch"
                placeholder="Property Name, Date, Email, Hunter Name, Phone Number"
                name="search"
                onChange={handleinputupcomings}
                // onPaste={handleinputupcomings}
                value={inputupcoming.search}
                onKeyDown={handleKeyDown}
              />
              {inputupcoming.search && (
                <button className="clear-btn" onClick={handleClearSearch}>×</button>
              )}
            </div>
            <button className="add-btn" onClick={handleinputupcoming}>Search</button>
          </div>
        ) : (
          <div className="search-div">
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control hunter-serch"
                placeholder="Property Name, Date, Email, Hunter Name, Phone Number"
                name="search"
                onChange={handleinputhistorys}
                // onPaste={handleinputhistorys}
                value={inputhistory.search}
                onKeyDown={handleKeyDownh}
              />
              {inputhistory.search && (
                <button className="clear-btn" onClick={handleClearSearchistory}>×</button>
              )}
            </div>
            <button className="add-btn" onClick={handleinputhistory}>Search</button>
          </div>
        )}

        <div className="reservation-main">
          <div className="reservation-btn">
            <button
              className={
                activeTab === "upcoming"
                  ? "active-button"
                  : "deactive-button"
              }
              onClick={() => setActiveTab("upcoming")}
            >
              Upcoming Reservations
            </button>
            <button
              className={
                activeTab === "history"
                  ? "active-button"
                  : "deactive-button"
              }
              onClick={() => setActiveTab("history")}
            >
              History
            </button>
          </div>
          {activeTab === "upcoming" && (
            <div className="reservation-table add-form">
              <div className="add-table upcoming_reservation_table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Hunter Name</th>
                      <th>Property Name</th>
                      <th>Reservation Date</th>
                      <th>Slot ID</th>
                      <th>Cost</th>
                      <th>Coupon Applied</th>
                      <th>Coupon Discount</th>
                      <th>Total</th>
                      <th>Payment ID</th>
                      <th>Purchased Date</th>
                      <th>Reservation Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && upcomingreservationlist && upcomingreservationlist.length ? upcomingreservationlist.map((reservation, ind) => (

                      <tr
                        key={reservation.id}
                        className={
                          reservation.orderstatus.toLowerCase() === "cancel"
                            ? "upcoming_cancel"
                            : reservation.orderstatus.toLowerCase() ===
                              "pending"
                              ? "upcoming_pending" : reservation.orderstatus.toLowerCase() ===
                                "cancelled"
                                ? "upcoming_cancelled"
                                : "upcoming_booked"
                        }
                      >
                        <td><Link to={`/upcomingreservations?user=${reservation.userid && reservation.userid._id ? reservation.userid._id : ''}`}
                        >{reservation && reservation.userid ? reservation.userid.firstname : ''} {reservation && reservation.userid ? reservation.userid.lastname : ''}</Link></td>
                        <td >
                          <span className="pro-link" onClick={() => gotopropertydetail(reservation, reservation.createdAt)}>
                            {reservation.cart[0].property.title}
                          </span>
                        </td>
                        <td>{reservation.cart.map((name) => (<p>{name.selecteddate ? convertdate(JSON.stringify(name.selecteddate)) : ''}</p>))}</td>
                        <td>{reservation.cart.map((name) => (name.isSlotCancelled?<div className="label-cancel"><div className="lable-status ribbon-red">Cancelled</div><p><Link to={"/propertydetails?index=" + reservation._id}>{name.selectedslot.name}</Link></p></div>:<p><Link to={"/propertydetails?index=" + reservation._id}>{name.selectedslot.name}</Link></p>))}</td>
                        <td>{reservation.cart && reservation.cart.length && reservation.cart[0].selectedslot.price ? reservation.cart.map((name) => (<p>$ {name.selectedslot.price}</p>)) : reservation.paymentdetail && reservation.paymentdetail.actualprice ? '$' + reservation.paymentdetail.actualprice : '$0'}</td>
                        <td>{reservation.couponapplied && reservation.couponapplied.couponcode ? reservation.couponapplied.couponcode : reservation.userid && reservation.userid.subscriptions && reservation.userid.subscriptions.length && reservation.userid.subscriptions[0].no_of_memberships ? "Membership" : "--"}</td>
                        <td>{reservation.couponapplied && reservation.couponapplied.discountType && reservation.couponapplied.discountType == 'percentage' ? '' : "$"}{reservation.couponapplied && reservation.couponapplied.couponamount ? reservation.couponapplied.couponamount : "0"}{reservation.couponapplied && reservation.couponapplied.discountType && reservation.couponapplied.discountType == 'percentage' ? '%' : ""}</td>
                        <td>{reservation.paymentdetail && reservation.paymentdetail.subtotal ? '$' + reservation.paymentdetail.total : '--'}</td>
                        <td>
                          {reservation.payment != null ? reservation.payment.paymentdata != null ? reservation.payment.paymentdata.paymenttId != null ? reservation.payment.paymentdata.paymenttId.length > 0 ? reservation.payment.paymentdata.paymenttId : '--' : '--' : '--' : '--'}
                        </td>
                        <td>
                          {convertdate(JSON.stringify(reservation.createdAt))}</td>
                        <td>
                          <button
                            className="reservation-actionbtn"
                            onClick={() =>
                              reservation.orderstatus.toLowerCase() ===
                              "cancel" && handleCancelclick()
                            }
                          >
                            <i className={reservation.orderstatus.toUpperCase() == 'PENDING' ? "fa-regular fa-clock" : reservation.orderstatus.toUpperCase() == 'BOOKED' ? "fa-solid fa-circle-check" : "fa-solid fa-circle-xmark"}></i>
                            {reservation.orderstatus.toUpperCase()}
                          </button>
                        </td>

                        {/* <td>
                          <div className="manage-editdiv">
                            <button className="manage-editbtn" id={'cancellationreason_' + reservation._id}
                              onClick={() => handleEditClick(reservation)}>
                              <i className="fa-solid fa-pen"></i>Edit
                            </button>
                          </div>
                        </td> */}

                        <td>
                        <div className="manage-editdiv">
                          <button className="reservation-actionbtns" id={'cancellationreason_' + reservation._id}
                            onClick={() => handleEditClick(reservation)}>
                            <i className="fa-solid fa-circle-xmark"></i>Cancel Slot
                          </button>
                        </div>
                      </td> 
                        
                         {/* <td>
                          {reservation.orderstatus != 'cancelled' ? <button className="reservation-actionbtns" id={'cancellationreason_' + reservation._id} onClick={() => setcancelreservation(reservation)}>
                            <i className="fa-solid fa-circle-xmark"></i>
                            CANCEL
                          </button> : <button className="reservation-actionbtns">
                            <i className="fa-solid fa-circle-xmark"></i>
                            CANCELLED
                          </button>}
                        </td> */}
                      </tr>
                    )) : loading ? 
                    <td colSpan="10" style={{ textAlign: 'center' }}>
                    <div style={{
                      paddingLeft: '10px',
                      paddingTop: '40px',
                      paddingBottom: '40px',
                      display: 'inline-block',
                    }}>Loading...</div></td> : <td colSpan="10" style={{ textAlign: 'center' }}><div style={{
                      paddingLeft: '10px',
                      paddingTop: '40px',
                      display: 'inline-block',
                    }}>No upcoming reservation!</div></td>}
                  </tbody>
                </table>
              </div>
              <div className="rowperpage">
                <div className="perpagecount">
                  Row Per Page&nbsp;
                  <select
                    onChange={inputupcomingperPage}
                    value={upcomingperPage}>
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                    <option value={200}>200</option>
                  </select>
                </div>
                {upcomingreservationlist.length ? <ReactPaginate
                  pageCount={Math.ceil(
                    upcomingreservationcount / upcomingperPage
                  )}
                  pageRangeDisplayed={upcomingperPage}
                  breakLabel={"..."}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination custom-pagination"}
                  activeClassName={"active"}
                /> : null}
              </div>
            </div>
          )}

          {activeTab === "history" && (
            <div className="reservation-table add-form">
              <div className="add-table history_reservation_table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Hunter Name</th>
                      <th>Property Name</th>
                      <th>Date</th>
                      <th>Slot ID</th>
                      <th>Cost</th>
                      <th>Coupon Applied</th>
                      <th>Coupon Discount</th>
                      <th>Total</th>
                      <th>Payment ID</th>
                      <th>Purchased Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody >
                    {!loading && historyreservationlist && historyreservationlist.length ? historyreservationlist.map((reservation, ind) => (
                      <tr
                        key={reservation.id}
                        className={
                          reservation.orderstatus.toLowerCase() === "cancelled"
                            ? "history_cancelled"
                            : reservation.orderstatus.toLowerCase() ===
                              "pending"
                              ? "history_pending" : reservation.orderstatus.toLowerCase() ===
                                "completed"
                                ? "history_completed"
                                : "history_booked"
                        }
                      >
                        <td> <Link
                          to={`/upcomingreservations?user=${reservation && reservation.userid && reservation.userid._id ? reservation.userid._id : ''}`}
                        >{reservation && reservation.userid ? reservation.userid.firstname : ''} {reservation && reservation.userid ? reservation.userid.lastname : ''}</Link></td>
                        <td >
                          <span className="pro-link" onClick={() => gotopropertydetail(reservation, reservation.createdAts)}>
                            {reservation.cart[0].property.title}
                          </span>
                        </td>
                        <td>{reservation.cart.map((name) => (<p>{name.selecteddate ? convertdate(JSON.stringify(name.selecteddate)) : ''}</p>))}</td>
                        <td>{reservation.cart.map((name) => (name.isSlotCancelled?<div className="label-cancel"><div className="lable-status ribbon-red">Cancelled</div><p><Link to={"/propertydetails?index=" + reservation._id}>{name.selectedslot.name}</Link></p></div>:<p>
                          <Link className="pro-link" to={"/propertydetails?index=" + reservation._id}>{name.selectedslot.name}</Link></p>))}</td>
                        <td>{reservation.cart && reservation.cart.length && reservation.cart[0].selectedslot.price ? reservation.cart.map((name) => (<p>$ {name.selectedslot.price}</p>)) : reservation.paymentdetail && reservation.paymentdetail.actualprice ? reservation.paymentdetail.actualprice : '$0'}</td>
                        <td>{reservation.couponapplied && reservation.couponapplied.couponcode ? reservation.couponapplied.couponcode : reservation.userid && reservation.userid.subscriptions && reservation.userid.subscriptions.length && reservation.userid.subscriptions[0].no_of_memberships ? "Membership" : "--"}</td>
                        <td>{reservation.couponapplied && reservation.couponapplied.discountType && reservation.couponapplied.discountType == 'percentage' ? '' : "$"}{reservation.couponapplied && reservation.couponapplied.couponamount ? reservation.couponapplied.couponamount : "0"}{reservation.couponapplied && reservation.couponapplied.discountType && reservation.couponapplied.discountType == 'percentage' ? '%' : ""}</td>
                        <td>{reservation.paymentdetail && reservation.paymentdetail.total ? '$' + reservation.paymentdetail.total : '$0'}</td>
                        <td>
                          {reservation.payment != null ? reservation.payment.paymentdata != null ? reservation.payment.paymentdata.paymenttId != null ? reservation.payment.paymentdata.paymenttId.length > 0 ? reservation.payment.paymentdata.paymenttId : '--' : '--' : '--' : '--'}
                        </td>
                        <td>
                          {convertdate(JSON.stringify(reservation.createdAt))}</td>
                        <td>
                          <button className="reservation-actionbtn">
                            <i className={reservation.orderstatus.toUpperCase() == "BOOKED" ? "fa-solid fa-circle-check" : reservation.orderstatus.toUpperCase() == "PENDING" ? "fa-regular fa-clock" : "fa-solid fa-circle-xmark"}></i>
                            {reservation.orderstatus.toUpperCase()}
                          </button>
                        </td>
                      </tr>
                    )) :  loading ? <td colSpan="10" style={{ textAlign: 'center' }}>
                    <div style={{
                      paddingLeft: '10px',
                      paddingTop: '40px',
                      paddingBottom: '40px',
                      display: 'inline-block',
                    }}>Loading...</div></td> : <td colSpan="10" style={{ textAlign: 'center' }}>
                    <div style={{
                      paddingLeft: '10px',
                      paddingTop: '40px',
                      paddingBottom: '40px',
                      display: 'inline-block',
                    }}>No reservations! </div></td>}
                  </tbody>
                </table>
              </div>
              <div className="rowperpage">
                <div className="perpagecount">
                  Row Per Page&nbsp;
                  <select
                    onChange={inputhistoryperPage}
                    value={historyperPage}>
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                    <option value={200}>200</option>
                  </select>
                </div>
                {historyreservationlist.length ? <ReactPaginate
                  pageCount={Math.ceil(
                    historyreservationcount / historyperPage
                  )}
                  pageRangeDisplayed={historyperPage}
                  breakLabel={"..."}
                  marginPagesDisplayed={2}
                  onPageChange={handlehistoryPageClick}
                  containerClassName={"pagination custom-pagination"}
                  activeClassName={"active"}
                /> : null}
              </div>
            </div>
          )}
        </div>
      </div>

      {cancelreservation && cancelreservation._id && (
        <div className="managecou-popup">
          <div className="managecou-content res-delete">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure?</h1>
              <p>Are you sure you want to cancel the reservation.</p>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  name="cancelreason"
                  onChange={inputsHandler}
                  value={step1inputField.cancelreason}
                ></textarea>
                <label for="floatingTextarea2">Reason...</label>
              </div>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                No
              </button>
              <button className="btn2" onClick={handlecancelreservation}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {showCancelLoader ?
        <div className="loader_section">
          <div className="res_loader">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : null
      }
    </>
  );
}
